
* {
	box-sizing: border-box;
}

@font-face {
  font-family: 'tktypeMedium';
  font-style: normal;
  font-weight: 600;
  src:url('./assets/fonts/tktypemedium.woff') format('woff');
}
@font-face {
  font-family: 'tktypeMedium';
  font-style: normal;
  font-weight: 500;
  src:url('./assets/fonts/tktypemedium.woff') format('woff');
}
@font-face {
  font-family: 'tktype';
  font-style: normal;
  font-weight: 400; 
  src: url('./assets/fonts/tktyperegular.woff') format('woff');
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:  #F6F7F8 !important;
  height: 100vh;
}
:root{
  --toastify-color-success: var(--tk-colors-brand-turquoise);
  --toastify-color-error: var(--tk-colors-brand-red);
  --toastify-font-family  : "tktypeMedium"
}

#root {
  height: 100vh;
  display: flex;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Oval1,
.Oval2,
.Oval3 {
  animation-name: load;
  animation-duration: 0.9s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  opacity: 0;
}
.Oval2 {
  animation-delay: 0.3s;
}
.Oval1 {
  animation-delay: 0.6s;
}


@keyframes load{
  0%{
    opacity: 0.5;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0.5;
  }
}


#orverAllChart{
color: "red";
}


/* .Toastify__toast--info {
  border-left: 4px solid var(--toastify-color-info)
}
.Toastify__toast--success{
  border-left: 4px solid var(--toastify-color-success)
}
.Toastify__toast--warning{
  border-left: 4px solid var(--toastify-color-warning)
}
.Toastify__toast--error {
  border-left: 4px solid var(--toastify-color-error)
} */